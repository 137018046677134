<template>
  <a-button class="editable-add-btn" style="margin-bottom: 8px" @click="showModal">Add</a-button>
  <a-table bordered :data-source="dataSource" :columns="columns">
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'title'">
        <div class="editable-cell">
          <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record.key].title" @pressEnter="save(record.key)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'content'">
        <div class="editable-cell">
          <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record.key].content" @pressEnter="save(record.key)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
          </div>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">

        <div>
          <div v-if="editableData[record.key]" >
            <check-outlined class="editable-cell-icon-check" v-if="!loadingSource" @click="save(record.key)" />
            <a-spin v-if="loadingSource" />
          </div>
          <div v-else >
            <!--            <edit-outlined class="editable-cell-icon" @click="edit(record.key)" />-->
            <a-button @click="edit(record.key)" style="margin-left: 10px" >Edit</a-button>
            <a-popconfirm
                v-if="dataSource.length"
                title="Sure to delete?"
                @confirm="onDelete(record.key)"
            >
              <a-button danger style="margin-left: 10px"  >Delete</a-button>
            </a-popconfirm>
          </div>

        </div>
      </template>
    </template>
  </a-table>
  <a-modal
      v-model:visible="visible"
      title="Please Enter FAQ Information"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
  >
    <div>
      <a-form :layout="formState.layout" model="horizontal" v-bind="formItemLayout">
        <a-form-item label="FAQ Title">
          <a-input v-model:value="formState.title" placeholder="FAQ Title"/>
        </a-form-item>
        <a-form-item label="FAQ Content">
          <a-input v-model:value="formState.content" placeholder="FAQ Content" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
import { collection, getDocs, getFirestore, doc, setDoc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";

const data = []
export default {
  name: "FAQ",
  components: {
    CheckOutlined,
    EditOutlined,
  },
  setup() {
    const loadingSource = ref(false)
    const db = getFirestore();
    const visible = ref(false);
    const confirmLoading = ref(false);
    const columns = [{
      title: 'FAQ ID',
      dataIndex: 'id',
      width: '12%',
    }, {
      title: 'FAQ Title',
      dataIndex: 'title',
      width: '15%'
    }, {
      title: 'FAQ Content',
      dataIndex: 'content',
    }, {
      title: 'operation',
      dataIndex: 'operation',
      width: '30%'
    }];
    const dataSource = ref(data);
    const count = computed(() => dataSource.value.length + 1);
    const editableData = reactive({});

    const edit = key => {
      editableData[key] = cloneDeep(dataSource.value.filter(item => key === item.key)[0]);
    };

    const save = async key => {
      loadingSource.value = true
      Object.assign(dataSource.value.filter(item => key === item.key)[0], editableData[key]);

      await updateDoc(doc(db, "FAQ", editableData[key].id), {
        title: editableData[key].title,
        content: editableData[key].content
      });
      loadingSource.value = false
      delete editableData[key];
    };

    const onDelete = async key => {
      await deleteDoc(doc(db, "FAQ", dataSource.value.filter(item => key === item.key)[0].id));
      dataSource.value = dataSource.value.filter(item => item.key !== key);
    };


    const showModal = () => {
      visible.value = true;
    };

    const handleOk = async () => {
      confirmLoading.value = true;
      const newData = {
        title: formState.title,
        content: formState.content
      };
      const docRef = await addDoc(collection(db, "FAQ"), newData);
      newData['id'] = docRef.id
      dataSource.value.push(newData);
      visible.value = false;
      confirmLoading.value = false;
    };

    const formState = reactive({
      layout: 'horizontal',
      title: '',
      content: '',
    });
    const formItemLayout = computed(() => {
      const {
        layout,
      } = formState;
      return layout === 'horizontal' ? {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 12,
        },
      } : {};
    });

    return {
      columns,
      onDelete,
      dataSource,
      editableData,
      count,
      edit,
      save,
      loadingSource,
      visible,
      confirmLoading,
      showModal,
      handleOk,
      formItemLayout,
      formState
    };
  },
  async created() {
    const db = getFirestore();
    const dataSource = ref(data);
    const queryFAQ = await getDocs(collection(db, "FAQ"));
    while (dataSource.value.length !== 0) {
      dataSource.value.pop()
    }
    let index = 0
    queryFAQ.forEach((doc) => {
      let dataNeedToPush = doc.data();
      dataNeedToPush['key'] = index + ""
      dataNeedToPush['id'] = doc.id
      dataSource.value.push(dataNeedToPush)
    })
    console.log(dataSource.value)
  }
}
</script>

<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>